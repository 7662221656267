@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;
@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,400italic,700,700italic);


//color-palette
$light-blue: #359dd7;
$navy-blue: #1d384f;
//body
$body-font-family: 'Ubuntu Mono', Courier, Arial, sans-serif;

body {
	font-family: $body-font-family;
	background: $light-blue;

	a, p, h1, h2, h3, h4, h5, h6, ul, li {
		color: $white;
		font-family: $body-font-family;
	}
}
.valign-middle {
    display: table;

    > .columns, .box {
        display: table-cell;
        vertical-align: middle;
    }

    > .columns, > [class*="column"] + [class*="column"]:last-child {
        float: none;
    }
}
.spacer {
	margin-bottom:1em;
}
.double.spacer {
	margin-bottom:2em;
}
.triple.spacer {
	margin-bottom:3em;
}
//Home Page
.home-container,
.bottom-menu {
	height:100%;
	overflow:hidden;
	img {
		margin-bottom: .25rem;
	}
	p {
		margin-bottom: 1.25rem;
	}
	.row {
		height: 100%;
	}
	.box, .box.columns {
		max-width: 320px;
		width: 320px;
		margin: 0 auto;
		padding: 10px;
	}
	.logo {
		width:100%;
		max-width:300px;
		height:auto;
		display: block;
	}
	.menu {
		font-size: 1.25em;
		line-height: 1.25;
		a { font-weight: 700;
		transition-duration: .2s; }
		a:hover { color: $navy-blue;
		 }
	}
}
.bottom-menu {
	height: auto;
}
//Portfolio 
.portfolio,
.portfolio-title,
.contact,
.footer {
	max-width:50rem;
	margin: 0 auto;
	.thumbnail { 
		-webkit-transition : border 200ms ease-out;
  		-moz-transition : border 200ms ease-out;
  		-o-transition : border 200ms ease-out;
  		transition : border 200ms ease-out;
  		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  		transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	}
	.thumbnail:hover {
		/*border-color: $navy-blue;*/
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	}
	a {
		transition-duration: .2s;
	}
	a:hover,
	a:active {
		color: $navy-blue;
	}
	h3 {
		margin-bottom: 1rem;
		font-weight: 700;
		color: #fff;
	}
	h5 {
		margin-bottom: 0rem;
	}
	@include breakpoint(small down) {
		h3 { font-size: 1.6em; }
	}
}
.portfolio-title {
	.column {
		margin-bottom: .5em;
		a {
			display: block;
		}
	}
}
.contact,
.footer {
	textarea {
		height:400px;
	}
}
.featured-image {
	margin-bottom: 2em;
	border: solid 10px #fefefe;
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}